<template>
  <div id="divFunWrapper">
    <div class="content-layout-fixer">
      <div class="fun-wrapper">
        <div class="fun-wrapper-contents">
          <div class="image-slider-">
            <BrandContentSlider swiperId="mainSliderFun"></BrandContentSlider>
          </div>
          <!-- <hr class="tabs-border tabs-border-top" /> -->
          <swiper ref="navSlider" :options="navSliderOption" class="tabs">
            <swiper-slide
              class="tab"
              v-for="item in contentTypes"
              :key="`${selectedId}${item.contentName}`"
              :class="{ activeTitle: item.contentTypeId === selectedId }"
            >
              <VueText
                @click="selectTab(item.contentTypeId, item.contentName)"
                :data-id="item.contentTypeId"
                :color="item.contentTypeId !== selectedId ? 'grey-30' : 'currentColor'"
                sizeLevel="5"
                weightLevel="3"
              >
                {{ item.contentName }}
              </VueText>
            </swiper-slide>
            <button class="lasttab"></button>
          </swiper>
          <hr class="tabs-border tabs-border-bottom" />
          <BrandContentList
            :title="'En Yeniler'"
            :contents="feeds"
            :hasFilter="true"
            :hasSearch="true"
            :showButton="isFeedContinue"
            @filterClicked="toggleShowFilter"
            @showMoreClicked="showMore"
            @outsideFilter="hideFilter"
            :eventTitle="eventTitle"
          >
            <div
              class="filter-menu"
              ref="filterMenu"
              v-if="showFilter"
              :key="selectedCategoryIndex.length"
            >
              <div class="filter-title">Kategoriler</div>
              <div class="filter-list">
                <VueListItem
                  @click="onRowSelected(item.contentTypeId, index)"
                  :text="item.contentName"
                  v-for="(item, index) in selectCategoryFilterConfig.filterBody"
                  :key="`${item.contentTypeId}${item.selected}`"
                  :icon="getIcon(item.selected)"
                  :contentAlignment="constants.flexAlignment.between"
                ></VueListItem>
              </div>
              <button class="btn-more" @click="submitFilters">
                <VueText sizeLevel="4" weightLevel="4">Filtrele</VueText>
              </button>
            </div>
          </BrandContentList>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import BrandContentList from '@/components/brand/BrandContentList/BrandContentList.vue';
import BrandContentSlider from '@/components/brand/Slider/BrandContentSlider.vue';
import { Fun } from '@/services/Api/index';
import { maxBy } from '@/mixins/arrayUtils.js';
import { mapGetters, mapState } from 'vuex';
import gtmUtils from '@/mixins/gtmUtils.js';
import StorageHelper from '@/utils/storageHelper';
import GtmHelper from '@/utils/gtmHelper';

export default {
  name: 'Fun',
  mixins: [gtmUtils],
  components: {
    VueText,
    VueListItem,
    swiper,
    swiperSlide,
    BrandContentList,
    BrandContentSlider,
  },
  computed: {
    ...mapGetters('app', ['getSearchedText']),
    ...mapGetters('fun', { selectedTabId: 'activeTab', showMoreCount: 'showMoreCount' }),
    ...mapState({ showMoreStatus: state => state.fun.showMoreStatus }),
    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    swiper() {
      return this.$refs.navSlider.swiper;
    },
    eventTitle() {
      let titleText = 'Yaşam';
      if (this.contentTypes && this.contentTypes.length > 0) {
        let selectedTabText = this.contentTypes.find(
          x => x.contentTypeId === this.selectedId || x.contentTypeId === this.filteredId,
        ).contentName;
        titleText += ` / ${selectedTabText}`;
      }
      return titleText;
    },
  },
  data() {
    return {
      isContentLoaded: false,
      feeds: [],
      lastIndex: 0,
      lastId: 0,
      pageSize: 3,
      isFeedContinue: false,
      showFilter: false,
      filteredId: null,
      filterCategoryName: '',
      selectedId: null,
      searchedText: '',
      selectCategoryFilterConfig: {
        filterBody: [],
      },
      selectedCategory: null,
      selectedCategoryIndex: [],
      contentTypes: [],
      navSliderOption: {
        slidesPerView: 'auto',
        threshold: 5,
      },
      from: null,
      to: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.from = from;
      vm.to = to;
    });
  },
  watch: {
    getSearchedText: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.setSearchContent(newVal);
          this.lastId = 0;
          this.lastIndex = 0;
          this.getContents(false, newVal);
        }
      },
    },
    selectedTabId: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.selectedId = newVal;
          this.selectTab(newVal);
        }
      },
    },
    selectedId: {
      handler() {
        if (this.selectedId === 0) {
          this.pushVirtualPvEvent();
        }
      },
      deep: true,
      immediate: true,
    },
    filterCategoryName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.pushVirtualPvEvent();
        }
      },
    },
  },
  mounted() {
    if (this.$route.params.selectedTab) {
      this.$store.dispatch('fun/selectedTab', 225);
    }
  },
  created() {
    this.getAllContents().then(() => {
      if (this.selectedTabId) {
        document.querySelector(`[data-id="${this.selectedTabId}"]`).click();
      }
      if (this.$route.query.contentTypeId) {
        document.querySelector(`[data-id="${this.$route.query.contentTypeId}"]`).click();
      }
    });
    window.addEventListener('click', e => {
      if (this.$refs.filterMenu) {
        if (
          this.showFilter &&
          !e.target.classList.contains('filter-button') &&
          !this.$refs.filterMenu.contains(e.target)
        ) {
          if (
            e.target.classList.contains('single-list-item') ||
            e.target.classList.contains('menu-icon-wrapper') ||
            e.target.tagName === 'svg' ||
            e.target.tagName === 'path' ||
            e.target.tagName.toLowerCase() === 'span'
          ) {
            this.showFilter = true;
          } else {
            this.showFilter = false;
          }
        }
      }
    });
  },
  methods: {
    pushVirtualPvEvent(filterName) {
      let userDetails = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
      }).get();
      let gtmHelper = new GtmHelper(userDetails);
      gtmHelper.initDataLayer(this.from, this.to);
      window.dataLayer
        .slice()
        .reverse()
        .find(i => i.event === 'commercial_data').page_path = filterName
        ? this.eventTitle + ' / ' + filterName
        : this.eventTitle;
    },
    async getAllContents() {
      return Fun.getContentParentCategories()
        .then(res => {
          this.contentTypes = res.data.Data.contentTypes;

          const firstItemId = this.contentTypes[0].contentTypeId;
          this.selectedId = firstItemId;
          return firstItemId;
        })
        .then(firstItemId => {
          Fun.getContentSubCategories(firstItemId)
            .then(res => {
              if (res && res.data) {
                const contentTypes = res.data.Data.contentTypes;
                if (contentTypes) {
                  contentTypes.forEach(item => {
                    this.selectCategoryFilterConfig.filterBody.push({ ...item, selected: false });
                  });
                }
              }
            })
            .then(() => {
              this.getContents(false);
              this.handleModalCategoryText();
            });
        });
    },
    toggleShowFilter() {
      this.showFilter = !this.showFilter;
    },
    hideFilter() {
      this.showFilter = false;
    },
    submitFilters() {
      this.hideFilter();
      this.lastIndex = 0;
      this.lastId = 0;
      let filterContentName = this.filterCategoryName;
      if (
        this.selectCategoryFilterConfig.filterBody &&
        Array.isArray(this.selectCategoryFilterConfig.filterBody) &&
        this.selectCategoryFilterConfig.filterBody.length > 0
      ) {
        let content = this.selectCategoryFilterConfig.filterBody.find(x => x.selected);
        if (content) {
          filterContentName = content.contentName;
        }
      }
      this.getContents(false, filterContentName);
    },
    selectTab(id, text) {
      this.setSearchContent('');
      this.hideFilter();
      this.selectedId = id;
      this.lastIndex = 0;
      this.lastId = 0;
      this.filteredId = null;
      this.pageSize = 3;
      let searchedText = '';
      this.$store.dispatch('app/setSearchedText', searchedText);
      this.$store.dispatch('fun/selectedTab', id);
      Fun.getContentSubCategories(this.selectedId).then(res => {
        if (res && res.data) {
          const contentTypes = res.data.Data.contentTypes;

          let filterBody = this.selectCategoryFilterConfig.filterBody;

          filterBody.splice(0, filterBody.length);

          if (contentTypes) {
            contentTypes.forEach(item => {
              filterBody.push({ ...item, selected: false });
            });
          }
        }
        this.getContents(false, text);
        this.handleModalCategoryText();
      });
    },
    showDetail(title, id) {
      this.selectTab(id);

      for (var i = 0; i < this.contentTypes.length; i++) {
        if (this.contentTypes[i].contentTypeId === id) {
          this.swiper.activeIndex = i + 1;
        }
      }
    },
    showMore() {
      this.lastIndex = this.feeds.length;
      this.lastId = maxBy(this.feeds, 'feedId');
      this.pageSize = 9;
      this.$store.dispatch('fun/showMoreCount', 12);
      this.getContents(true);
    },
    getContents(isNextPage, filter) {
      if (filter) {
        this.pushDataLayerEvent('filterContent', { filterName: filter });
      }
      Fun.getContent(
        this.lastId,
        this.lastIndex,
        this.showMoreCount,
        this.filteredId || this.selectedId,
        this.searchedText,
      ).then(res => {
        const {
          Data: { contents, isFeedContinue },
        } = res.data;
        this.isFeedContinue = isFeedContinue;
        if (isNextPage) {
          this.feeds.push(...contents);
        } else {
          this.feeds = contents;
        }
        if (this.feeds.length) {
          this.lastIndex = this.feeds.length;
          this.lastId = this.feeds[this.lastIndex - 1].feedId;
        }
      });
    },
    handleModalCategoryText() {
      if (this.selectedId) {
        this.contentTypes.forEach(item => {
          if (item.contentTypeId === this.selectedId) {
            this.filterCategoryName = item.contentName;
          }
        });
      }
    },
    getIcon(isSelected) {
      if (isSelected) {
        return ICON_VARIABLES['checked'];
      } else {
        return ICON_VARIABLES['circle'];
      }
    },
    onRowSelected(id, index) {
      this.selectCategoryFilterConfig.filterBody
        .filter(item => item.selected === true)
        .map(item => (item.selected = false));
      this.selectCategoryFilterConfig.filterBody[index].selected = !this.selectCategoryFilterConfig
        .filterBody[index].selected;
      if (this.filteredId != id) {
        this.filteredId = id;
      } else {
        this.filteredId = null;
        this.selectCategoryFilterConfig.filterBody[index].selected = false;
      }
    },
    setSearchContent(newVal) {
      this.searchedText = newVal;
    },
  },
};
</script>
<style lang="scss" scoped>
.fun-wrapper {
  overflow: hidden;
  &-contents {
    overflow: auto;
    min-height: 1250px;
  }
}

.fun-wrapper-contents > div > .image-slider-container {
  margin-top: palette-space-level('30');
  .swiper-slide {
    width: 100%;
    height: 250px;
  }
}
.tabs-border {
  border-bottom: none;
  border-top: 1px solid rgba(palette-color-level('grey', '20'), 0.6);
  &-bottom {
    position: relative;
    top: -2px;
  }
  &-top {
    margin-top: palette-space-level(10);
  }
}
.tabs {
  z-index: 5;
  align-items: flex-start;
  background-color: transparent;
  z-index: 5;
  padding-left: palette-space-level(10);
  .tab {
    width: fit-content !important;
    p {
      padding: palette-space-level(20) palette-space-level(20) 13px palette-space-level(20);
      white-space: nowrap;
      text-transform: uppercase;
      display: inline-block;
      position: relative;
      cursor: pointer;

      &::after {
        content: '';
        border-radius: 22px;
        z-index: 2;
        position: absolute;
        width: calc(100% - 40px);
        height: 3px;
        bottom: 0px;
        left: 20px;
        background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
        opacity: 0;
        transition: all 0.3s ease;
      }
    }

    &.activeTitle {
      position: relative;
      & p::after {
        opacity: 1;
      }
    }
  }
  .lasttab {
    pointer-events: none;
    opacity: 0;
  }
}

/deep/ .content-card-list {
  position: relative;
  &:first-child {
    margin-top: palette-space-level(60);
  }
  .title-wrapper {
    margin-bottom: palette-space-level(20);
  }
}
/deep/ .image-slider-container {
  padding-bottom: 0;
}
/deep/ .filter-menu {
  position: absolute;
  background-color: palette-color('white-100');
  z-index: 5;
  width: calc(100% - 20px);
  top: 60px;
  border: 1px solid palette-color('grey-20');
  border-radius: palette-radius-level(2);
  margin-bottom: palette-space-level(30);
  .filter-title {
    display: block;
    padding: palette-space-level(30) palette-space-level(20) palette-space-level(20)
      palette-space-level(30);
    font-size: 18px;
    font-weight: 500;
  }
  .filter-list {
    height: 251px;
    overflow: auto;
    margin-bottom: palette-space-level(60);
    margin-right: 60px;
    padding-right: palette-space-level(20);
    scrollbar-color: palette-color('grey-40') palette-color('grey-10');
    scrollbar-width: thin;
    .single-list-item {
      padding-left: palette-space-level(30);
      &:first-child {
        border-top: 0.5px solid rgba(palette-color-level('grey', '20'), 0.6);
      }
    }
  }
}
.btn-more {
  display: block;
  margin: auto;
  margin-bottom: palette-space-level(30);
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  width: 8px;
  border-radius: 5.5px;
  background-color: palette-color('grey-10');
}
::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 5.5px;
  background-color: palette-color('grey-40');
}
</style>
